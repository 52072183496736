<template>
    <div id="send-comment-page">
        <toolbar-back-button-component :title="$t('send_comment_page.send_comments')" />

        <div class="pa-4">
            <p class="font-weight-bold font-18">
                {{ $t('send_comment_page.think_customer_area') }}
            </p>

            <p class="font-color-light mb-4 font-14">
                {{ $t('send_comment_page.provide_best_possible_experience') }}
            </p>

            <v-btn block class="mb-2" color="secondary" large outlined @click="navigateTo('parameter_comment_opinion')">
                <v-icon class="pr-2" color="secondary">mdi-message-text-outline</v-icon>
                <span class="font-weight-bold secondary--text">{{ $t('send_comment_page.give_opinion') }}</span>
            </v-btn>

            <v-btn block color="error" large @click="navigateTo('parameter_comment_bug')">
                <v-icon class="pr-2" color="white">mdi-message-alert-outline</v-icon>
                <span class="font-weight-bold white--text">{{ $t('send_comment_page.signal_bug') }}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from '../../../components/Global/ToolbarBackButtonComponent'

export default {
    name: 'SendCommentPage',
    components: { ToolbarBackButtonComponent },
}
</script>
